'use client';
import React from 'react';
import styles from './StarsPercent.module.scss';

interface StarsPercentProps {
	rating: number;
	size?: string;
}

const StarsPercent: React.FC<StarsPercentProps> = ({ rating, size }) => {
	const percent = (rating / 5) * 100;

	return (
		<div
			className={styles.StarsPercent}
			style={
				{
					'--rating': rating?.toString(),
					'--percent': `${percent}%`,
					fontSize: `${size ? size : '24'}px`
				} as React.CSSProperties
			}
			aria-label={`Rating of this product is ${rating} out of 5.`}
		/>
	);
};

export default StarsPercent;
