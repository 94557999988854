import styles from './DiscountLable.module.scss';
import cls from 'classnames';

const DiscountLabel = ({ p_is_flash_sale, price, mrp, discountPercentage, size = 'large' }) => {
	return (
		<>
			{p_is_flash_sale ? (
				<>
					{!!discountPercentage && (
						<span className={cls(styles.FlashSale, styles[size])}>
							<span>{discountPercentage}% OFF</span>
						</span>
					)}
				</>
			) : (
				<>
					{!!discountPercentage && (
						<span className={cls(styles.DiscountLabel, styles[size])}>
							<span>{discountPercentage}% OFF</span>
						</span>
					)}
				</>
			)}
		</>
	);
};

export default DiscountLabel;
